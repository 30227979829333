<template>
  <!-- <html> -->
  <b-col>
    <div id="sessionContainer"></div>
    <container class="formRegister">
      <div class="card card-custom gutter-b">
        <div class="textheader">
          <p>Zoom Video</p>
          <img
            src="/media/svg/icons/Media/icons8-recording.gif"
            class="logo"
            alt="Logo"
            v-show="isButtonRecording"
          />
        </div>
        <div class="form-container">
          <div class="form-group">
            <label for="topic"
              >Room Name<span v-if="topic === ''" class="error">*</span></label
            >
            <input
              type="text"
              id="topic"
              name="topic"
              placeholder="Tên buổi họp"
              v-model="topic"
              :disabled="isButtonDisabled"
              required
            />
          </div>
          <div class="form-group">
            <label for="name"
              >Your Name<span v-if="user_identity === ''" class="error">*</span>
            </label>
            <input
              type="text"
              id="user_identity"
              name="user_identity"
              placeholder="Tên người dùng"
              v-model="user_identity"
              :disabled="isButtonDisabled"
              required
            />
          </div>

          <!-- <div class="form-group">
  <label for="role">Role</label>
  <select id="role" name="role" v-model="role" class="selected" title="Nếu tạo cuộc họp thì Role mặc định là host" >
    <option value="0">User</option>
    <option value="1">Host</option>
  </select>
</div> -->
          <button
            class="btnCreateSession"
            @click="initSession"
            v-show="!isButtonDisabled"
          >
            Start Session
          </button>
          <button
            class="btnCreateSession"
            @click="copyLinkSurvey"
            v-show="isButtonDisabled"
          >
            Copy Link
          </button>
          <!-- <button class="btnCreateSession" @click="sessionClosed">
            Refresh
          </button> -->
        </div>
      </div>
    </container>
    <b-row>
      <div id="previewContainer"></div>
    </b-row>
  </b-col>
  <!-- <head>
    <meta http-equiv="origin-trial" content="AqvR8nKtld1/lThpwH1Tmg2M4OnHIb2p40U6CnYIUOY6xj3Nsz46ibNkeTRpMrxFwTZ/o63sJmv590YIhEmudAUAAABgeyJvcmlnaW4iOiJodHRwOi8vbG9jYWxob3N0OjgwODAiLCJmZWF0dXJlIjoiVW5yZXN0cmljdGVkU2hhcmVkQXJyYXlCdWZmZXIiLCJleHBpcnkiOjE3MTkzNTk5OTl9" />
  </head>
  </html> -->

  <!-- </b-row> -->
</template>

<script>
import KTLayoutAsideToggle from '@/assets/js/layout/base/aside-toggle.js';
import uitoolkit from '@zoom/videosdk-ui-toolkit';
import '@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css';
import { generateSessionToken } from './js/tool.js';
import { getConfig } from './js/config.js';
import { v4 as uuidv4 } from 'uuid';
export default {
  name: 'ZoomLogin',
  props: {
    msg: String,
  },
  data() {
    return {
      sessionToken: '',
      previewContainer: '',
      sessionContainer: '',
      topic: '',
      password: '1',
      user_identity: '',
      role: 1,
      isButtonDisabled: false,
      isButtonRecording: false,
      sessionData: '',
    };
  },
  mounted() {},

  computed: {
    //   showLogo() {
    //   return this.isButtonRecording;
    // }
  },

  methods: {
    copyLinkSurvey() {
      if (this.topic && this.topic.trim() !== '') {
        const img = 'http://diab.com.vn/wp-content/uploads/2024/03/zoom.jpg';
        const name = 'Trao đổi cùng chuyên gia và huấn luyện viên của DIAB ⬆️⬆️⬆️';
        const longDynamicLink = `https://click.diab.com.vn/meet/?link=https://diab.com.vn/?calendar%3D${this.topic}&apn=com.vbhc.diab&isi=1569353448&sd=Ứng dụng hoàn toàn miễn phí giúp kiểm soát bệnh đái tháo đường và kết nối với chuyên gia&ibi=com.cactusoftware.diab&si=${img}&st=${name}`;
        this.$api
          .getFirebaseDynamicLinks({
            longDynamicLink: longDynamicLink,
          })
          .then((dynamicLink) => navigator.clipboard.writeText(dynamicLink));
        this.$toastr.s({
          title: 'Thành công!',
          msg: 'Đã sao chép link',
        });
      } else {
        this.$toastr.e({
          title: 'Thất bại!',
          msg: 'Sao chép đường dẫn không thành công. Kiểm tra lại thông tin phòng',
        });
      }
    },
    minLogo() {
      // Emit event to update field value in component A
      this.$root.$emit('isToggleSidebar', false);
    },

    toastCreateError() {
      this.$toastr.e({
        title: 'Thất bại!',
        msg: 'Đã có lỗi xảy ra, vui lòng kiểm tra lại thông tin',
      });
    },

    toastCreateSuccess() {
      this.$toastr.s({
        title: 'Thành công!',
        msg: 'Thao tác thành công!',
      });
      // aside toggle
      KTLayoutAsideToggle.getToggle().toggleOn();
      this.minLogo();
    },

    async fetchSession() {
      try {
        // Make the API call
        const response = await this.$api.get('/Zoom/sessions');

        // Check if response status is OK

        // Extract session data from response
        this.sessionData = response.sessions;

        // Find session with matching topic
        const matchingSession = this.sessionData.find(
          (session) => session.session_name == this.topic,
        );
        if (matchingSession) {
          // Check if recording has started for the matching session
          if (!matchingSession.has_recording) {
            // If recording has not started, start the recording
            this.recordingCloud(matchingSession.id);
          } else {
            console.log('Recording has already been started or not found');
          }
        } else {
          // If session with matching topic is not found, retry the API call
          await this.fetchSession();
        }
      } catch (error) {
        throw new Error('Failed to fetch session data');
      }
    },

    async recordingCloud(sessionId) {
      let encodedSessionId = encodeURIComponent(sessionId);

      try {
        const response = await this.$api.patch(
          `/Zoom/startRecording?sessionId=${encodedSessionId}`,
        );
        if (response.status === 200) {
          // Success
          console.log('Recording started successfully');
        } else {
          console.log(`Failed to start recording. Status: ${response.status}`);
        }
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      }
    },

    async initSession() {
      const sessionConfig = await getConfig(
        this.topic,
        this.password,
        this.user_identity,
      );

      this.sessionToken = await generateSessionToken(
        sessionConfig.sdkKey,
        sessionConfig.sdkSecret,
        sessionConfig.topic,
        sessionConfig.password,
        sessionConfig.user_identity,
        sessionConfig.sessionKey,
        parseInt(sessionConfig.role, 10),
        '',
        '',
        uuidv4(),
      );

      if (this.topic !== '' && this.user_identity !== '') {
        this.toastCreateSuccess();
        this.isButtonDisabled = true;
        this.isButtonRecording = true;
        await this.initJoinSession();

        setTimeout(async () => {
          await this.fetchSession();
        }, 10000);
      } else {
        this.toastCreateError();
      }
    },

    sessionClosed() {
      if (this.topic) {
        if (window.confirm('Are you sure you want to close the session?')) {
          //    console.log('session closed');
          this.sessionContainer = document.getElementById('sessionContainer');
          this.isButtonDisabled = false;
          this.isButtonRecording = false;
          this.topic = '';
          this.user_identity = '';
          uitoolkit.closeSession(this.sessionContainer);
        } else {
          // User clicked cancel, do nothing
        }
      }
    },

    initPreview() {
      this.previewContainer = document.getElementById('previewContainer');
      uitoolkit.openPreview(this.previewContainer);
    },

    initJoinSession() {
      this.sessionContainer = document.getElementById('sessionContainer');

      var config = {
        videoSDKJWT: this.sessionToken,
        sessionName: this.topic,
        userName: this.user_identity,
        sessionPasscode: this.password,
        features: ['video', 'audio', 'share', 'chat', 'users', 'settings'],
      };
      //     console.log(config)
      // uitoolkit.closePreview(this.previewContainer)
      //  console.log(this.role);
      //  if (this.role==0) {
      //   config.features = ['video', 'audio','chat', 'users']
      //  }
      //  else{
      //   config.features = ['video', 'audio', 'share', 'chat', 'users', 'settings']
      //  }
      //  if(this.topic !== '' || this.user_identity !== '')
      //  {
      //  this.toastCreateSuccess();
      uitoolkit.joinSession(this.sessionContainer, config);
    },
  },
};
</script>

<style scoped>
.error {
  color: red;
  margin-left: 5px;
}
#previewContainer {
  width: 50%;
  height: 50%;
  object-fit: cover;
  margin-left: 25%;
  margin-top: 3%;
  overflow: hidden;
}

.videosdk-video-element {
  width: 35%;
  height: 35%;
}

.btnCreateSession {
  margin-top: 5%;
  margin-left: 20%;
  width: 60%;
  background-image: linear-gradient(-180deg, #37aee2 0%, #1e96c8 100%);
  border: none;
  border-radius: 0.5rem;
  color: #ffffff;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
  padding: 10px 15px;
  text-align: center;
  display: inline-block;
}
.btnPreview {
  margin-top: 5%;
  margin-left: 20%;
  width: 60%;
  background-image: linear-gradient(-180deg, #37aee2 0%, #1e96c8 100%);
  border: none;
  border-radius: 0.5rem;
  color: #ffffff;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
  padding: 10px 15px;
  text-align: center;
  display: inline-block;
}

.button:hover {
  background-color: #45a049; /* Darker green on hover */
}
.user-view {
  width: 50%;
  height: 50%;
  object-fit: cover;
  margin-left: 0%;
  overflow: hidden;
}
/* CSS */
#cdk-overlay-container {
  width: 50%;
  height: 50%;
  object-fit: cover;
  margin-left: 0%;
  overflow: hidden;
}
.inputsession {
  margin-top: 50%;
}
.form-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input {
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 15px;
}
.textheader {
  text-align: center;
  font-size: 35px;
  font-weight: bold;
  margin-bottom: 10px;
}
.selected {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
/* .btnCreateSession:disabled {
  background-color: grey !important;
  color: white !important; 
} */
</style>
