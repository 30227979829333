// export default {
//     // sdkKey: 'hsfKYyjnTkmQ1fxaB_mZbQ',
//     // sdkSecret: 'z242HVf7X6je0NzOA97WFfP3GgqtUTKseyYm',
//     sdkKey: 'mGEaJOJsQcW8OGAXZzawsg',
//     sdkSecret: 'cKwuffl2tTQXLLheIar6YQP7axs8HA3rbVpZ',
//     topic: 'tpc-001',
//     password: '',
//     name: 'ALan',
//     sessionKey: '',
//     user_identity: '',
//     // The user role. 1 to specify host or co-host. 0 to specify participant, Participants can join before the host. The session is started when the first user joins. Be sure to use a number type.
//     role: 1
// };


export function getConfig(topic='',password='',name='')
{
return {
    // sdkKey: 'hsfKYyjnTkmQ1fxaB_mZbQ',
    // sdkSecret: 'z242HVf7X6je0NzOA97WFfP3GgqtUTKseyYm',
    sdkKey: 'mGEaJOJsQcW8OGAXZzawsg',
    sdkSecret: 'cKwuffl2tTQXLLheIar6YQP7axs8HA3rbVpZ',
    topic: topic,
    password: password,
    name: name,
    sessionKey: '',
    user_identity: '',
    // The user role. 1 to specify host or co-host. 0 to specify participant, Participants can join before the host. The session is started when the first user joins. Be sure to use a number type.
    role: 1
}}